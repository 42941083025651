// Services.js
import React, { useEffect, useState, useRef } from 'react';
import './services.css';
import Lottie from 'lottie-react';
import { FaArrowDownLong } from 'react-icons/fa6';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

const Services = () => {
  const [activeSection, setActiveSection] = useState(-1);
  const containerRef = useRef(null);
  const navigate = useNavigate()

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      const containerTop = container.getBoundingClientRect().top;
      const containerHeight = container.getBoundingClientRect().height;
      const sectionHeight = containerHeight / 6;

      // Determine the active section based on scroll position
      const scrollPosition = window.scrollY - (containerTop + window.scrollY);

      if (scrollPosition < 0) {
        setActiveSection(0);
      } else if (scrollPosition < sectionHeight) {
        setActiveSection(0);
      } else if (scrollPosition < 3 * sectionHeight) {
        setActiveSection(1);
      } else {
        setActiveSection(2);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const serviceItems = [
    {
      title: "Customized Aggregated Investment Ecosystem",
      text: "Experience a world-class trading environment specially curated just for you, integrating a suite of investment services in one place. From our international associations, we bring you Well Regulated and Well Recognized Brokers packed along with advanced services and investment options",
      animation: require('../../Images/First.json'),
    },
    {
      title: "SumBoT3.0 – AI Enabled Financial Research- Simplified",
      text: "Unlock the power of Al with SumBot3.0, your personal financial research assistant. Trained on billions of data points, SumBot3.0 delivers deep market insights and analysis to keep you ahead in the trading game.",
      animation: require('../../Images/Chatbot.json'),
    },
    {
      title: "Automated Investing and Copy Trading",
      text: "Gain Access to some of the most renowned Investment Dealers, Portfolio Managers &b Traders. Bring Automation to your investments with algorithms and copy trades.",
      animation: require('../../Images/Second.json'),
    },
  ];

  return (
    <div className="service-container" ref={containerRef}>
      <div className='service-container-sticky'>
        {serviceItems.map((item, index) => (
          <div
            key={index}
            className={`sticky-section ${index === activeSection ? 'active' : ''} ${index < activeSection ? 'past' : ''}`}
          >
            {/* <p className="service-header mfs-2 fwr">Services {index + 1}</p> */}
            {
              index == 2 &&
            <Link
              activeClass="active"
              to={"homeAboutPC"}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <button
                class='homeDownBtn' >
                <p><FaArrowDownLong class='fw-bold' /></p>
              </button>
            </Link>
            }
            <div>
              <p className={`${index === activeSection ? 'mfs-5' : 'mfs-4'} fwr`}>{item.title}</p>
              <p className={`service-description ${index === activeSection ? 'active mfs-2' : 'mfs-1'} fwt`}>{item.text}</p>
              <button class='sumBtn mfs-4 rounded-5 mt-3' onClick={() => navigate('/service')}>Explore</button>

              <div className={`serviceImageBox  ${index === activeSection ? 'active ' : ''}`}>
                <Lottie animationData={item.animation} loop={true} style={{ height: '100%', width: '100%', }} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
