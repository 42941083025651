import React, { useEffect, useState } from 'react'
import ScrollTrigger from 'react-scroll-trigger'
import Typewriter from 'typewriter-effect'
import CountUp from 'react-countup';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HomeScroll from './HomeScroll';
import Services from './Services';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import HomeAbout2 from './HomeAbout2';
import HomeFooterTop from './HomeFooterTop';
import ServiceSmall from './ServiceSmall';
import './home.css'
import '../../App.css'
import { useNavigate } from 'react-router-dom';
import HomeAbout3 from './HomeAbout3';
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6';
import { title } from 'framer-motion/client';


const homeData = {
    homeTop:{
        title: `<p>BEST THINGS COME IN <span class='fw-bold'>3</span> </p> `,
        text: ["You", "Markets","Us","You -> Markets <- Us"],
        buttonName: "Log in",
        buttonUrl: "link"
    },
    homeSeconnd: {
        videoLinkLarg: "link",
        videoLinkSmall: "link"
    }
    ,
    services: [
        {
            title: "Specially Curated Trading Environment",
            text: "Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art technology, ensuring you trade with confidence and security.",
            svg: 'link',
            buttonName: "Service",
            buttonUrl: "link"
        },
        {
            title: "Automated Copy Trading with Global Experts ",
            text: "Gain access to over 500 expert traders from around the world. Review their strategies, performance history, and rankings before committing as little as $200 per trader. Diversify your investments across different asset classes, traders and strategies. ",
            svg: 'link',
            buttonName: "Service",
            buttonUrl: "link"
        },
        {
            title: "Meet SumBot3.0 - AI-Enabled Financial Research",
            text: "Unlock the power of Al with SumBot3.0, your personal financial research assistant. Trained on billions of data points, SumBot3.0 delivers deep market insights and analysis to keep you ahead in the trading game.",
            svg: 'link',
            buttonName: "Service",
            buttonUrl: "link"
        }
    ],
    why:
    {
        title: "Why 3SUM?",
        text: "<p>3Sum was created out of a simple realization: most trading platforms cater to advanced traders, leaving everyday people feeling overwhelmed and excluded. We noticed that while many are interested in exploring the capital markets, they're often discouraged by the complexity and jargon of traditional trading tools. They promise big returns and advanced tools, but as we dug deeper, one thing became crystal clear-these platforms are designed for the pros, the full-time traders who already speak the language of the markets.</p>",
        bottom_text: 'Lets do markets, together!',
        buttonName: "Know More",
        buttonUrl: "link"
    }
    ,
    participate: {
        carts: [
            {
                no: "01",
                title: "sign up",
                text: "stablish ",
                buttonName: "Log in",
                buttonUrl: "link"
            },
            {
                no: "02",
                title: "sign up",
                text: "stablish ",
                buttonName: "Log in",
                buttonUrl: "link"
            },
            {
                mo: "03",
                title: "sign up",
                text: "stablish ",
                buttonName: "Log in",
                buttonUrl: "link"
            },
        ],
        bottom_text: 'Lets do markets, together!'
    },
    what:
    {
        title: "what is 3SUM?",
        text: "<p>3Sum was created out of a simple realization: most trading platforms cater to advanced traders, leaving everyday people feeling overwhelmed and excluded. We noticed that while many are interested in exploring the capital markets, they're often discouraged by the complexity and jargon of traditional trading tools. They promise big returns and advanced tools, but as we dug deeper, one thing became crystal clear-these platforms are designed for the pros, the full-time traders who already speak the language of the markets.</p>",
        bottom_text: 'Lets do markets, together!',
        buttonName: "Know More",
        buttonUrl: "link"
    },
    checkSelf: {
        title1: "Are you ",
        title2: "Penny wish ",
        title3: "and",
        title4: "pound foolish",
        buttonName: "Check Yourself",
        buttonUrl: "link"
    },
    counterCart: [
        {
            title: "Years </br> Trading",
            text: "20",
            extra: ''
        },
        {
            title: "Hours</br> Investing",
            text: "25",
            extra: 'k'

        },
        {
            title: "Portfolios</br> Analyzed",
            text: "1000",
            extra: ''

        },

    ],
    partner: {
        title: "Our Partner",
        ideas1: [
            "Fx-Pro",
            "CMC Markets",
            "How to Trade",
            "Wallstreet Coach",
            "Finchat",

        ],
        ideas2: [
            "Wallstreet 101",
            "Forex UPS",
            "FINLATO",
            "Track a Trade",
            "Mark Dickinson",
        ]

    },
    savings: {
        title: "Why risk your entire capital for the sake of minor savings?",
        text: "At 3Sum, we focus on what truly matters—your growth, your safety, and your success. Let’s build something substantial together."
    },
    portfolio: {
        title: "Level up your portfolio with us",
        p1: "level 1",
        p2: "level 2",
        p3: "level 3",
        p4: "level 4",
        p5: "level 5",
        p6: "level 6",
        p7: "level 7",
        p8: "level 8",
        p9: "level 9",
        p10: "level 10",
    }

}

const Home = () => {

    const [counterOn, setCounterOn] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0);
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const [finalText, setFinalText] = useState(null);

    let sum3UserAccessToken = localStorage.getItem("sum3UserAccessToken");
    let sum3UserRole = localStorage.getItem("sum3UserRole");

    // console.log(currentSectionIndex);



    // const sectionIds = ["home1", "home2", "home3", "home4", "home5", "home6", "home7", "home8", "home9", "home10"];
    // const scrollToNextSection = () => {
    //     if (currentSectionIndex >= sectionIds.length - 1) {
    //         window.scrollTo({
    //             top: 0,
    //             behavior: "smooth",
    //         });
    //         setCurrentSectionIndex(0);
    //     } else {
    //         const nextSectionId = sectionIds[currentSectionIndex + 1];
    //         const nextSection = document.getElementById(nextSectionId);
    //         if (nextSection) {
    //             nextSection.scrollIntoView({ behavior: "smooth" });
    //             setCurrentSectionIndex(currentSectionIndex + 1); // Move to the next section
    //         }
    //     }
    // };

    const handleScroll = () => {
        setScrollPosition(window.scrollY / 1000);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, [])

    const navigate = useNavigate()
    return (
        <div>
            <div className='homeHero' id='home1'>
                <div class=' col-11 m-auto'>
                    <p class='mfs-7  fwt' data-aos="fade-in" dangerouslySetInnerHTML={{ __html: homeData?.homeTop?.title }}></p>
                    <div className='mfs-8 fweb org'>

                        <Typewriter
                            options={{
                                strings: homeData?.homeTop?.text,
                                autoStart: true,
                                loop: true, // No looping
                                delay: 200,
                                cursor: '', // Hides the blinking cursor after typing
                            }}

                        />


                    </div>
                    {(!sum3UserAccessToken && !sum3UserRole) &&
                        <button class='sumBtn mfs-4 rounded-5 mt-3' onClick={() => navigate('/log-in')}>Log in</button>
                    }
                    <div class='arrows'>
                        <p class='d1 fs-1'><i class="bi bi-caret-down-fill"></i> </p>
                        <p class='d2 fs-1'><i class="bi bi-caret-down-fill"></i> </p>
                        <p class='d3 fs-1'><i class="bi bi-caret-down-fill"></i> </p>
                    </div>
                </div>
            </div>

            {/* <div class='position-relative'>
                <HomeScroll />
            </div> */}

            <div class='servicePc mt-5 ' id='home3'>
                <Services />
            </div>

            <div class='serviceMobile' id='home4'>
                <ServiceSmall />
            </div>

            <div class='homeAboutPC' id='home5'>
                <HomeAbout2 />
            </div>
            <div class='homeAboutMB' id='home6'>
                <HomeAbout3 />
            </div>


            {/* <div class='row col-11 m-auto gap-lg-0 gap-5 mt-3' id='home7'>
                {homeData?.counterCart?.map((el) => {
                    return (
                        <div class='col-lg-4'  >
                            <div class='col-11  rounded-top-5 h-100 text-start p-2 m-auto' style={{ background: '#f2f2f2' }}>
                                <div class='col-11 m-auto'>
                                    <p class='mfs-6 fwm my-3' dangerouslySetInnerHTML={{ __html: el?.title }}></p>
                                </div>
                                <div class='text-end d-flex align-items-center justify-content-end mfs-8 '>
                                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                        <p class='mfs-8 fwsb'  ><span  >{counterOn && <CountUp start={0} end={el.text} delay={0} />}</span></p>
                                    </ScrollTrigger>
                                    <p class='fwsb'>
                                        {el?.extra}+
                                    </p>
                                </div>

                            </div>
                        </div>
                    )
                })}
            </div> */}

            <div class='row col-11 m-auto gap-lg-0 gap-5 mt-3' id='home7'>
                {homeData?.counterCart?.map((el) => {
                    return (
                        <div class='col-lg-4'  >
                            <div class='col-11  rounded-top-5 h-100 text-start p-2 m-auto' style={{ background: '#f2f2f2' }}>
                                <div class=' d-flex align-items-center  mfs-8 '>
                                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                        <p class='mfs-8 fwsb'  ><span  >{counterOn && <CountUp start={0} end={el.text} delay={0} />}</span></p>
                                    </ScrollTrigger>
                                    <p class='fwsb'>
                                        {el?.extra}+
                                    </p>
                                </div>
                                <div class='col-11 m-auto text-end'>
                                    <p class='mfs-6 fwm my-3 text-end' dangerouslySetInnerHTML={{ __html: el?.title }}></p>
                                </div>

                            </div>
                        </div>
                    )
                })}
            </div>

            <div class='col-11 m-auto my-5  bg-blackGray rounded-4' id='home8' style={{ overflow: 'hidden' }}>
                <br />
                <div class='text-start text-white'>
                    <div class='col-11 m-auto text-start text-white'>
                        <div class='col-md-6 text-start fbnr '>
                            <p class='mfs-7 fbnr fweb' > Our Partners</p>
                        </div>
                    </div>

                    <div class='ourPartnerPc'>
                        <ParallaxProvider>
                            <Parallax translateX={[-50, 50]}>
                                <div class="d-flex gap-4 mt-4"  >
                                    {homeData?.partner?.ideas1?.map((e) => {
                                        return (
                                            <button class='btn btn-outline-light fbnt rounded-5 fs-4 text-nowrap px-5 fwm '>
                                                {e}
                                            </button>
                                        )
                                    })
                                    }

                                </div>
                            </Parallax>
                        </ParallaxProvider>
                        <ParallaxProvider>
                            <Parallax translateX={[50, -50]} >
                                <div class="d-flex gap-4 mt-4 justify-content-end"  >
                                    {homeData?.partner?.ideas2?.map((e) => {
                                        return (
                                            <button class='btn btn-outline-light fbnt rounded-5 fs-4 text-nowrap px-5 fwm '>
                                                {e}
                                            </button>
                                        )
                                    })

                                    }
                                </div>
                            </Parallax>
                        </ParallaxProvider>
                    </div>

                    <div class='ourPartnerMb '>
                        <div class="marquee">
                            <div class="marquee__item">
                                <>
                                    {homeData?.partner?.ideas1?.map((e) => {
                                        return (
                                            <button class='btn btn-outline-light fbnt rounded-5 mx-3 fs-4 text-nowrap px-5 fwm '>
                                                {e}
                                            </button>
                                        )
                                    })
                                    }
                                    {homeData?.partner?.ideas2?.map((e) => {
                                        return (
                                            <button class='btn btn-outline-light fbnt mx-3 rounded-5 fs-4 text-nowrap px-5 fwm '>
                                                {e}
                                            </button>
                                        )
                                    })
                                    }
                                </>


                            </div>
                        </div>
                    </div>

                    <div class='col-11 m-auto'>

                        {(!sum3UserAccessToken && !sum3UserRole) &&
                            <button class='sumBtn mfs-4 rounded-5 mt-3' onClick={() => navigate('/log-in')}>Log in</button>
                        }
                        {/* <button class='sumBtn mfs-4 mt-5  rounded-5' onClick={() => navigate('/log-in')}>Log in</button> */}
                        <br />
                    </div>

                </div>
                <br />
            </div>
            <div class='col-11 m-auto home9 my-5' id='home9'  >
                <p class='fbnh mfs-7 org fwh'>Why risk your entire capital for the sake of minor savings?</p>
                <p class='fs-5 tJustify col-lg-6 col-md-8 col-11 m-auto'> At 3Sum, we focus on what truly matters—your growth, your safety, and your success. Let’s build something
                    substantial together.</p>
            </div>
            <div id='home10'>

                <HomeFooterTop />
            </div>
            <br />
        </div>
    )
}

export default Home