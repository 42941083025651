import Lottie from 'lottie-react'
import React, { useEffect, useRef } from 'react'
import './homeScroll.css'
import { useNavigate } from 'react-router-dom';
import { FaArrowDownLong } from 'react-icons/fa6';
import { Link } from 'react-scroll';

const ServiceSmall = () => {
  const service1 = useRef(null);
  const service2 = useRef(null);
  const service3 = useRef(null);
  const navigate = useNavigate()

  // useEffect(() => {
  //     const div = document.querySelector(".serviceSmall1");
  //     const json = document.querySelector(".servisJson1"); 
  //     const observer = new IntersectionObserver(
  //       ([entry]) => {
  //         if (entry.isIntersecting) {

  //                 div.classList.add("active")
  //                 json.classList.add("active") 

  //         } else {
  //             div.classList.remove("active"); 
  //             json.classList.remove("active");  
  //         }
  //       },
  //       { rootMargin: "0px", threshold: 0.5 }
  //     );
  //     if (service1.current) {
  //       observer.observe(service1.current);
  //     }
  //     return () => {
  //       if (service1.current) {
  //         observer.unobserve(service1.current);
  //       }
  //     };
  //   }, [service1]);

  //   useEffect(() => {
  //     const div = document.querySelector(".serviceSmall2");
  //     const json = document.querySelector(".servisJson2"); 
  //     const observer = new IntersectionObserver(
  //       ([entry]) => {
  //         if (entry.isIntersecting) {

  //                 div.classList.add("active")
  //                 json.classList.add("active") 

  //         } else {
  //             div.classList.remove("active"); 
  //             json.classList.remove("active");  
  //         }
  //       },
  //       { rootMargin: "0px", threshold: 0.5 }
  //     );
  //     if (service2.current) {
  //       observer.observe(service2.current);
  //     }
  //     return () => {
  //       if (service2.current) {
  //         observer.unobserve(service2.current);
  //       }
  //     };
  //   }, [service2]);

  //   useEffect(() => {
  //     const div = document.querySelector(".serviceSmall3");
  //     const json = document.querySelector(".servisJson3"); 
  //     const observer = new IntersectionObserver(
  //       ([entry]) => {
  //         if (entry.isIntersecting) {

  //                 div.classList.add("active")
  //                 json.classList.add("active") 

  //         } else {
  //             div.classList.remove("active"); 
  //             json.classList.remove("active");  
  //         }
  //       },
  //       { rootMargin: "0px", threshold: 0.5 }
  //     );
  //     if (service3.current) {
  //       observer.observe(service3.current);
  //     }
  //     return () => {
  //       if (service3.current) {
  //         observer.unobserve(service3.current);
  //       }
  //     };
  //   }, [service3]);
  return (
    <div>
      <div class='row col-11 m-auto text-start gap-4'>
        <div class='rounded-4 col-12 serviceSmall1 position-relative' ref={service1} >
          <div class='col-11 my-3'>
            {/* <div class='rounded-3 p-1 px-3 border' style={{ width: 'fit-content', background: 'rgba(0,0,0,0.1)' }}>Service 1</div> */}
            <div class='mt-2'>
              <p class='fs-1'> Customized Aggregated Investment Ecosystem  </p>
              <p class='mt-2'>Experience a world-class trading environment specially curated just for you, integrating a suite of investment services in one place. From our international associations, we bring you Well Regulated and Well Recognized Brokers packed along with advanced services and investment options.</p>
              <button class='sumBtn mfs-4 rounded-5 mt-3' onClick={() => navigate('/service')}>Explor</button>
              <Link
                activeClass="active"
                to={"homeAboutMB"}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <button
                  class='homeDownBtn' >
                  <p><FaArrowDownLong class='fw-bold' /></p>
                </button>
              </Link>
            </div>
            <div class='col-11 m-auto servisJson1 active'>
              <Lottie
                animationData={require('../../Images/First.json')}
                loop={true}
                style={{ height: '100%' }} // Adjust height and width as needed
              />
            </div>
          </div>
        </div>

        <div class='rounded-4 col-12 serviceSmall2' ref={service2} >
          <div class='col-11 my-3'>
            {/* <div class='rounded-3 p-1 px-3 border' style={{ width: 'fit-content', background: 'rgba(0,0,0,0.1)' }}>Service 2</div> */}
            <div class='mt-2'>
              <p class='fs-1'> SumBoT3.0 – AI Enabled Financial Research- Simplified</p>
              <p class='mt-2'>Unlock the power of Al with SumBot3.0, your personal financial research assistant. Trained on billions of data points, SumBot3.0 delivers deep market insights and analysis to keep you ahead in the trading game.</p>
              <button class='sumBtn mfs-4 rounded-5 mt-3' onClick={() => navigate('/service')}>Explor</button>
            </div>
            <div class='col-11 m-auto servisJson2 active'>
              <Lottie
                animationData={require('../../Images/Chatbot.json')}
                loop={true}
                style={{ height: '100%' }} // Adjust height and width as needed
              />
            </div>
          </div>
        </div>

        <div class='rounded-4 col-12 serviceSmall3' ref={service3} >
          <div class='col-11 my-3'>
            {/* <div class='rounded-3 p-1 px-3 border' style={{ width: 'fit-content', background: 'rgba(0,0,0,0.1)' }}>Service 3</div> */}
            <div class='mt-2'>
              <p class='fs-1'>Automated Investing and Copy Trading</p>
              <p class='mt-2'>Gain Access to some of the most renowned Investment Dealers, Portfolio Managers &b Traders. Bring Automation to your investments with algorithms and copy trades.</p>
              <button class='sumBtn mfs-4 rounded-5 mt-3' onClick={() => navigate('/service')}>Explor</button>
            </div>
            <div class='col-11 m-auto servisJson3 active'>
              <Lottie
                animationData={require('../../Images/Second.json')}
                loop={true}
                style={{ height: '100%', }} // Adjust height and width as needed
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceSmall 
